<template>
  <div>
    <a-modal v-model:visible="visible" title="关联标准疾病" @ok="handleOk" okText="确认" cancelText="取消"
      :afterClose="closeModal">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="名称">
          <diseaseSelector v-model="id" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { AddRelation } from "/src/api/syndrome.js";
import diseaseSelector from '/src/components/selector/diseaseSelector.vue';
import { Update } from "/src/api/disease.js";
export default {
  components: {
    diseaseSelector,
  },
  data() {
    return {
      visible: this.modelValue,
      reload: false,
      visible2: false,
      medicineFilter: "",
      prescription: "",
      id: "",
      name: "",
      editableData: reactive({}),
      selectedRows: {},
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      editableData: reactive({}),
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filterNameInitial(input) {
      this.medicineFilter = input?.toLowerCase();
    },
    filter() {
      return true
    },
    async handleOk() {
      let _this = this;
      // let parentId = this.$store.state.syndromeList.find(item => item.name == this.name).id
      if (this.record.id == this.id) {
        _this.$message.warning("不能关联自身", function () { });
        return
      }
      let res = await Update({ id: this.record.id, standardDiseaseId: this.id })
      if (res.errCode == 0) {
        _this.$message.success("关联成功", function () { });
        _this.visible = false
        _this.reload = true
        this.name = ""
      }

    },

    closeModal() {
      this.$emit('update:modelValue', false);
      if (this.reload) {
        this.$emit("reload")
      }
    },

  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped></style>
