<template>
  <a-select v-model:value="current" @search="filterNameInitial" :filterOption="filter" showSearch
    style="width: 201px;margin-right:10px">
    <a-select-option v-for="item in list" :value="item.id">{{ item.name }}</a-select-option>
  </a-select>
</template>

<script>
export default {
  data() {
    return {
      myFilter: "",
    };
  },
  created() {

  },
  mounted() {
    // console.log(this.$store.state.medicineList)
    // console.log(this.$store.state.medicineList.filter(item => item.id == 2416))
  },
  activated() {

  },
  emits: ['update:modelValue'],
  //获取父元素传值
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    list() {
      let list = this.$store.state.diseaseList;
      console.log(355555);
      console.log(list);

      if (!this.myFilter) {
        return list.slice(0, 200);
      }
      return list.filter(item => item.name?.toLowerCase().includes(this.myFilter) || item.nameInitial?.toLowerCase().includes(this.myFilter)).slice(0, 200);
      // return [{ name: 1, id: 1 }]
    },
    current: {
      get() {
        return this.modelValue;
      },
      set(val) {
        console.log(val);
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    filterNameInitial(input) {
      this.myFilter = input?.toLowerCase();
    },
    filter() {
      return true
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}

.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>