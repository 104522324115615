<template>
  <div>
    <a-modal v-model:visible="visible" title="疾病详情" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal"
      width="50vw" :okButtonProps="{ style: { display: 'none' } }">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <div class="detailModal">
          <div><label>名称：</label><span>{{ record?.name }}</span></div>
          <div><label>首字母：</label><span>{{ record?.nameInitial }}</span></div>

        </div>

        <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="medicineId"
          :pagination="false" bordered>
          <template #title>
            <div class="thead">别名列表</div>
          </template>
          <template #operation="{ record }">
            <div class="flex_sa">
              <div class="editable-row-operations">
                <span>
                  <a-popconfirm title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </div>

            </div>

          </template>

        </a-table>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { Detail, Search, Update } from "/src/api/disease.js";
export default {
  components: {
  },
  data() {
    return {
      visible: this.modelValue,
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "30%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "首字母",
          key: "nameInitial",
          dataIndex: "nameInitial",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "nameInitial"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {

    async detail() {
      console.log(this.record);
      let res = await Detail(this.record?.id);
      let res2 = await Search({ standardDiseaseId: this.record?.id });
      // let res = await Search();
      if (res2.data?.records?.length > 0) {
        this.data = res2.data.records;
      } else {
        this.data = []
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    async onDelete(id) {
      console.log(this.record);
      let res = await Update({ id, standardDiseaseId: 0 });
      this.detail()
    },


  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.tableStyle {
  border: 1px solid #f0f0f0;
  padding: 10px;
}

.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5em;
}

.tr {
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}

.tr>div {
  width: 12.5%;
  padding: 5px 12px;
}
</style>
